import React from 'react';
import { Layout } from '../../../components/Layout';

const EngineeringSurveys = () => (
  <Layout
    title="Engineering Surveys Calgary & Area | Axiom Geomatics"
    description="Professionally supporting other engineering disciplines, our work includes volume calculations, slope stability surveys and more."
    keywords="topographical surveys, slope stability, grading surveys, parking lot surveys, industrial survey, volume survey, volume calculations, borrow-pits, road staking, road surveys, land contour survey, utility construction survey, storm water surveys, flood survey, flood mitigation, bore-hole"
  >
    <main>
      <h1>Engineering Surveys Calgary & Area</h1>
      <p>
        Some projects are so large in size they involve multiple teams from multiple disciplines.
        The data and products Axiom Geomatics produce provide insight into water drainage, slope
        stability, topographical issues and more.
      </p>

      <p>We commonly offer the following services:</p>

      <ul>
        <li>Topographical surveys</li>
        <li>Slope stability</li>
        <li>Grading surveys</li>
        <li>Bore-hole surveys</li>
        <li>Volume survey and calculations</li>
        <li>Borrow pits</li>
        <li>Road/highway surveys and staking</li>
        <li>Land contour surveys</li>
        <li>Utility construction surveys</li>
        <li>Storm water surveys</li>
        <li>Flood mitigation surveys</li>
      </ul>
    </main>
  </Layout>
);

export default EngineeringSurveys;
